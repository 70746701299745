import API from "@/httpsAPI/API";
import request from "@/utils/request";
import {downloadFile} from "@/httpsAPI/common"

// 项目任务资料列表
export function getProjectManageTaskFileList(params) {
    return request({
      url:API.projectTaskFileList,
      method:'post',
      data:params
    })
}

// 删除任务资料
export function deleteTaskFileList(params) {
    return request({
      url: '/project/file/delete',
      method:'post',
      data:params
    })
}

// 批量下载接口
export function batchDownload(fileName, params) {
    return downloadFile('/project/file/download', fileName, params, 'post')
}

// 单一下载
export function fileDownload(params) {
  return downloadFile('/project/file/file-download/' + params.id, params.fileName, null, 'post')
}